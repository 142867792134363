import flashDispatcherService from '@web/atomic/legacy/obj.flash-wrapper/flash-dispatcher.service';
import { usePostUsingNoAuth } from '@web/data/use-post-with-no-auth';

interface TokenizeCardRequest {
  type: 'card';
  card: {
    number: string;
    holder_name: string;
    exp_month: number;
    exp_year: number;
    cvv: string;
  };
}

interface TokenizeCardResponse {
  id: string;
  type: string;
  created_at: string;
  expires_at: string;
  card: {
    first_six_digits: string;
    last_four_digits: string;
    holder_name: string;
    exp_month: number;
    exp_year: number;
    brand: string;
  };
}

export const useCardTokenization = (onCardInfoError: () => void) => {
  const handleTokenizeError = (err) => {
    const isCardInfoError = err.data.message === 'The request is invalid.';
    const errorMsg = isCardInfoError
      ? 'Parece que há um erro nos dados do cartão informado. Revise os dados e tente novamente.'
      : 'Ocorreu um erro. Por favor, tente novamente em 1 minuto.';
    flashDispatcherService.dispatchMessage(errorMsg, 'alert');
    if (isCardInfoError) {
      onCardInfoError();
    }
  };
  return usePostUsingNoAuth<TokenizeCardRequest, TokenizeCardResponse>({
    url: 'https://api.pagar.me/core/v5/tokens?appId=pk_test_AXyK6nOkSrtP3vZa',
    onError: handleTokenizeError,
  });
};
