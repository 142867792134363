import { CheckoutBoletoRequest, CheckoutBoletoResponse, PostCheckoutBoletoUrl } from '@global/utils/api/checkout.controller.model';
import { FaIcon } from '@web/atomic';
import { usePostUsingNoAuth } from '@web/data/use-post-with-no-auth';
import { BillingFormData, CheckoutPageStep, CheckoutPaymentMethods, CheckoutPaymentMethodStrategy } from '../utils/checkout-page.model';
import { handleCheckoutErrorHOF } from '../utils/handle-checkout-error-hof';

interface UseBoletoPaymentMethodStrategy extends CheckoutPaymentMethodStrategy {
  boleto?: CheckoutBoletoResponse;
}
export const useBoletoPaymentMethodStrategy = (
  userId: string,
  alternativeUserName: string,
  changeStep: (nextStep: CheckoutPageStep) => void
): UseBoletoPaymentMethodStrategy => {
  const [checkoutBoleto, checkoutBoletoState] = usePostUsingNoAuth<CheckoutBoletoRequest, CheckoutBoletoResponse>({
    url: PostCheckoutBoletoUrl,
    onError: handleCheckoutErrorHOF(),
  });
  const performPaymentSubmission = async (data: BillingFormData) => {
    await checkoutBoleto({
      userId,
      personal: {
        name: data.name ?? alternativeUserName,
        email: data.email,
        document: data.document,
        phone: data.phone,
      },
    });
    changeStep(CheckoutPageStep.Boleto);
  };

  const submitLoading = checkoutBoletoState.loading;

  return {
    boleto: checkoutBoletoState.data,

    // CheckoutPaymentMethodStrategy
    performPaymentSubmission,
    submitLoading,
    nextStepFromPaymentSelection: CheckoutPageStep.BillingData,
    previousStepFromBillingDataStep: CheckoutPageStep.PaymentSelection,

    // CheckoutPaymentMethodData
    raw: CheckoutPaymentMethods.Boleto,
    icon: FaIcon.Boleto,
    name: 'Boleto',
  };
};
