import { Blob2SectionStyled } from '@lp-root/src/components/atm.background/background.styled';
import { Body, BodySecondary, Col, GrayColor, Grid, H2, Hbox, Row, Separator } from '@web/atomic';
import { CardWrapper } from '@web/atomic/atm.wrapper/wrapper.component';
import React from 'react';
import { CheckoutPaymentMethodData, CheckoutPaymentMethods } from '../utils/checkout-page.model';

interface ICheckoutPaymentSelectionProps {
  paymentMethods: CheckoutPaymentMethodData[];
  onSelect: (paymentMethod: CheckoutPaymentMethods) => void;
}

export const CheckoutPaymentSelection: React.FunctionComponent<ICheckoutPaymentSelectionProps> = (props) => {
  return (
    <>
      <Blob2SectionStyled>
        <Grid>
          <Row mb>
            <Col xs={12} sm={10} md={8} lg={6}>
              <H2>Forma de pagamento</H2>
              <Body>Como você prefere pagar?</Body>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={10} md={8} lg={6}>
              {props.paymentMethods.map((p) => (
                <React.Fragment key={p.raw}>
                  <CardWrapper onClick={() => props.onSelect(p.raw)}>
                    <Hbox vAlign="center">
                      <Hbox.Item vAlign="center" wrap>
                        <p.icon size="sm" color={GrayColor.GrayDark} />
                      </Hbox.Item>
                      <Hbox.Separator />
                      <Hbox.Item vAlign="center">
                        <BodySecondary kind={'default'}>{p.name}</BodySecondary>
                      </Hbox.Item>
                    </Hbox>
                  </CardWrapper>
                  <Separator />
                </React.Fragment>
              ))}
            </Col>
          </Row>
        </Grid>
      </Blob2SectionStyled>
    </>
  );
};
