import { GatsbyButton } from '@components/atm.button/button.component';
import { parseDateToDDMMYYYY } from '@global/utils/date/parse-to-string';
import { formatAsPrice } from '@global/utils/number/format';
import { Body, Hbox, Separator } from '@web/atomic';
import React from 'react';
import CouponCode from '../../order-success/coupon-code.component';
import OrderSuccess from '../../order-success/order-success.component';

interface ICheckoutBoletoInfoProps {
  amount: number;
  barcode_img: string;
  due_at: string;
  line: string;
  pdf_url: string;
  url: string;
}

export const CheckoutBoletoInfo: React.FunctionComponent<ICheckoutBoletoInfoProps> = (props) => {
  return (
    <OrderSuccess headline={'Sucesso 🎉! Agora só falta pagar o boleto abaixo!'} hideDownload={true}>
      <>
        <Body>
          Abaixo você encontra o boleto no valor de {formatAsPrice(props.amount / 100, true)} e com vencimento para{' '}
          {parseDateToDDMMYYYY(new Date(props.due_at))}. Saiba que o boleto leva em média 3 dias úteis para ser reconhecido e liberarmos o
          programa para você.
        </Body>
        <Body>
          Você pode abrir boleto{' '}
          <a href={props.url} target="_blank" rel="noreferrer">
            clicando aqui
          </a>{' '}
          ou, se preferir, utilize a linha de código de barras abaixo para pagamento:
        </Body>
        <CouponCode code={props.line} />
        <Separator />
        <Hbox hAlign={'center'}>
          <GatsbyButton kind={'secondary'} external to={props.pdf_url}>
            Ver boleto completo{' '}
          </GatsbyButton>
        </Hbox>
      </>
    </OrderSuccess>
  );
};
