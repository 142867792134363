import { CheckoutPixRequest, CheckoutPixResponse, PostCheckoutPixUrl } from '@global/utils/api/checkout.controller.model';
import { FaIcon } from '@web/atomic';
import { usePostUsingNoAuth } from '@web/data/use-post-with-no-auth';
import { BillingFormData, CheckoutPageStep, CheckoutPaymentMethods, CheckoutPaymentMethodStrategy } from '../utils/checkout-page.model';
import { handleCheckoutErrorHOF } from '../utils/handle-checkout-error-hof';

interface UsePixPaymentMethodStrategy extends CheckoutPaymentMethodStrategy {
  pix?: CheckoutPixResponse;
}
export const usePixPaymentMethodStrategy = (
  userId: string,
  alternativeUserName: string,
  changeStep: (nextStep: CheckoutPageStep) => void
): UsePixPaymentMethodStrategy => {
  const [checkoutPix, checkoutPixState] = usePostUsingNoAuth<CheckoutPixRequest, CheckoutPixResponse>({
    url: PostCheckoutPixUrl,
    onError: handleCheckoutErrorHOF(),
  });

  const performPaymentSubmission = async (data: BillingFormData) => {
    await checkoutPix({
      userId,
      personal: {
        name: data.name ?? alternativeUserName,
        email: data.email,
        document: data.document,
        phone: data.phone,
      },
    });
    changeStep(CheckoutPageStep.Pix);
  };

  const submitLoading = checkoutPixState.loading;

  return {
    pix: checkoutPixState.data,

    // CheckoutPaymentMethodStrategy
    performPaymentSubmission,
    submitLoading,
    nextStepFromPaymentSelection: CheckoutPageStep.BillingData,
    previousStepFromBillingDataStep: CheckoutPageStep.PaymentSelection,

    // CheckoutPaymentMethodData
    raw: CheckoutPaymentMethods.Pix,
    icon: FaIcon.Pix,
    name: 'PIX',
  };
};
