import { formatAsPrice } from '@global/utils/number/format';
import { H3, Hbox, ProductOldPrice } from '@web/atomic';
import React from 'react';
import { CheckoutFooterPlaceholder, CheckoutFooterWrapper } from './checkout-footer.component.styled';

interface ICheckoutFooterProps {
  amount: number;
  oldAmount: number;
}

export const CheckoutFooter: React.FunctionComponent<ICheckoutFooterProps> = (props) => {
  return (
    <>
      <CheckoutFooterWrapper>
        <Hbox vAlign="baseline">
          <Hbox.Item>
            <H3 kind="light" cell>
              Total
            </H3>
          </Hbox.Item>
          {props.oldAmount && (
            <>
              <Hbox.Item wrap>
                <ProductOldPrice kind="light"> {formatAsPrice(props.oldAmount / 100, true)} </ProductOldPrice>
              </Hbox.Item>
              <Hbox.Separator />
            </>
          )}
          <Hbox.Item wrap>
            <H3 kind="light" cell>
              {formatAsPrice(props.amount / 100, true)}
            </H3>
          </Hbox.Item>
        </Hbox>
      </CheckoutFooterWrapper>
      <CheckoutFooterPlaceholder />
    </>
  );
};
