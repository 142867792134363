import { Border, FontSize, Spacing } from '@web/atomic';
import styled from 'styled-components';

export const PixCodeText = styled.p`
  user-select: all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: ${Spacing.Medium};
  border: 1px dashed #ccc;
  border-radius: ${Border.Radius};
  font-size: ${FontSize.Small};
  margin-bottom: ${Spacing.Medium};
`;
