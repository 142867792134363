import { GrayColor, LightColor, Spacing } from '@web/atomic';
import styled from 'styled-components';

export const CheckoutFooterPlaceholder = styled.div`
  height: 59px;
`;

export const CheckoutFooterWrapper = styled.div`
  border-top: 1px solid ${GrayColor.White};
  position: fixed;
  bottom: 0;
  width: 100%;
  background: ${LightColor.Primary};
  padding: ${Spacing.Medium} ${Spacing.Large};
`;
