export enum CheckoutPageStep {
  PaymentSelection = 'payment-selection',
  CardData = 'card-data',
  BillingData = 'billing-data',
  Boleto = 'boleto',
  Pix = 'pix',
}

export enum CheckoutPaymentMethods {
  CreditCard = 'credit-card',
  Boleto = 'boleto',
  Pix = 'pix',
}

export interface CheckoutPaymentMethodData {
  raw: CheckoutPaymentMethods;
  icon: React.FunctionComponent<any>;
  name: string;
}

export interface AddressFormData {
  zipCode?: string;
  street?: string;
  number?: string;
  complement?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
}

export interface BillingFormData extends AddressFormData {
  name?: string;
  email?: string;
  document: string;
  phone: string;
}

export interface CheckoutPaymentMethodStrategy extends CheckoutPaymentMethodData {
  performPaymentSubmission: (data: BillingFormData) => Promise<void>;
  nextStepFromPaymentSelection: CheckoutPageStep;
  previousStepFromBillingDataStep: CheckoutPageStep;
  submitLoading: boolean;
}

export interface CheckoutPageQueryParams {
  // base64 encoded json (@see CheckoutPageDecodedQueryParams)
  d: string;
}

export interface CheckoutPageDecodedQueryParams {
  userId: string;
  price: number;
  priceOld: number;
}

export interface CardDataFormData {
  cardNumber: string;
  ccname: string;
  expirationdate: string;
  cvv2: string;
  cardFlag: string;
}
