import { GatsbyButton } from '@components/atm.button/button.component';
import { sendDataLayer } from '@web/utils/analytics';
import { BackButton } from '@lp-root/src/components/atm.back-button';
import { Body, Col, Grid, H1, H2, InputLabel, Row, Separator } from '@web/atomic';
import { PhoneField } from '@web/atomic/legacy/atm.text-field/phone-field.component';
import { TextField } from '@web/atomic/legacy/atm.text-field/text-field.component';
import { Form, FormContext, FormContextState, FormData, Validators } from '@web/atomic/legacy/obj.form';
import React from 'react';
import { BillingFormData, CheckoutPaymentMethods } from '../utils/checkout-page.model';
import { AddressForm } from './address-form.component';
import { CheckoutFooter } from './checkout-footer.component';

interface IBillingDataProps {
  initialData: BillingFormData;

  name: string;
  hideName: boolean;
  email: string;
  phone: string;

  submitLoading: boolean;
  paymentMethod: CheckoutPaymentMethods;
  onSubmit: (data: BillingFormData) => void;
  onBackButton: (data: BillingFormData) => void;
}

export const BillingData: React.FunctionComponent<IBillingDataProps> = (props) => {
  const handleSubmit = async (formData: FormData<BillingFormData>) => {
    if (Object.keys(formData.error).length !== 0) return;
    const data = formData.data;
    sendDataLayer({
      event: 'custom_click',
      id: `ga-BillingData-submit`,
    });
    props.onSubmit(data);
  };

  return (
    <Grid>
      <Form onSubmit={handleSubmit}>
        <FormContext.Consumer>
          {(formConsumer: FormContextState) => {
            const handleBackClick = () => {
              const formData: FormData<BillingFormData> = formConsumer.getFormData() as any;
              props.onBackButton(formData.data);
            };
            return (
              <Row mt>
                <Col xs={12} sm={10} md={8} lg={6}>
                  <BackButton onClick={handleBackClick} forceShow={true} />
                </Col>
              </Row>
            );
          }}
        </FormContext.Consumer>

        <Row mt mb>
          <Col xs={12} sm={10} md={8} lg={6}>
            <H1>Dados de cobrança</H1>
            <Body>Agora só faltam mais alguns dados seus para garantir a segurança do pagamento.</Body>
          </Col>
        </Row>

        <Row mb>
          <Col xs={12} sm={10} md={8} lg={6}>
            <H2>Identificação do pagador</H2>
          </Col>
        </Row>

        {props.hideName || (
          <Row mb>
            <Col xs={12} sm={10} md={8} lg={6}>
              <Form.Field name="name" initialValue={props.initialData?.name ?? props.name} validators={[Validators.Required()]}>
                <InputLabel>Nome</InputLabel>
                <TextField type={'text'} autoComplete="name" />
              </Form.Field>
              <Separator />
            </Col>
          </Row>
        )}

        <Row mb>
          <Col xs={12} sm={10} md={8} lg={6}>
            <Form.Field
              name="email"
              initialValue={props.initialData?.email ?? props.email}
              validators={[Validators.Required(), Validators.EmailRegex('O e-mail preenchido não é válido')]}
            >
              <InputLabel>E-mail</InputLabel>
              <TextField type={'email'} autoComplete="email" />
            </Form.Field>
            <Separator />
          </Col>
        </Row>

        <Row mb>
          <Col xs={12} sm={10} md={8} lg={6}>
            <Form.Field
              name="document"
              initialValue={props.initialData?.document}
              validators={[Validators.Required(), Validators.CpfOrCnpjValidation('O número digitado não é um CPF ou CNPJ válido.')]}
            >
              <InputLabel>CPF ou CNPJ</InputLabel>
              <TextField type={'document'} />
            </Form.Field>
            <Separator />
          </Col>
        </Row>

        <Row mb>
          <Col xs={12} sm={10} md={8} lg={6}>
            <Form.Field
              name="phone"
              initialValue={props.initialData?.phone ?? props.phone}
              validators={[Validators.Required(), Validators.PhoneRegex('O número digitado é inválido')]}
            >
              <InputLabel>Celular/telefone</InputLabel>
              <PhoneField />
            </Form.Field>
            <Separator />
          </Col>
        </Row>

        {CheckoutPaymentMethods.CreditCard === props.paymentMethod && <AddressForm initialData={props.initialData} />}

        <Row mb>
          <Col xs={12} sm={10} md={8} lg={6}>
            <GatsbyButton type="submit" kind="call-to-action" loading={props.submitLoading} expanded>
              Finalizar pedido
            </GatsbyButton>
          </Col>
        </Row>
      </Form>
    </Grid>
  );
};
