import { Hbox, InputLabel } from '@web/atomic';
import { LazyLoadImage } from '@web/atomic/legacy/atm.lazy-load-image';
import { TextFieldMasked } from '@web/atomic/legacy/atm.text-field/text-field-masked.component';
import { Form, Validators } from '@web/atomic/legacy/obj.form';
import { useField } from '@web/atomic/obj.custom-hooks/field.hook';
import { useGetUsingNoAuth } from '@web/data/use-get-with-no-auth';
import { onlyNumber } from '@global/utils/string/only-number';
import React, { useEffect } from 'react';
import { CardFlagWrapper } from './card-number-field.component.styled';

interface ICardNumberProps {
  initialValue: string;
}

interface CardBinResponse {
  brand: string;
  brandName: string;
  gaps: number[];
  lenghts: number[];
  mask: string;
  cvv: number;
  brandImage: string;
  possibleBrands: string[];
}

export const CardNumberField: React.FunctionComponent<ICardNumberProps> = (props) => {
  const [cardNumber, handleCardNumber] = useField(props.initialValue);
  const onlyNumbers = onlyNumber(cardNumber);

  // https://docs.pagar.me/reference#bin
  const { data: binData, error: binError, refetch: fetchBin } = useGetUsingNoAuth<unknown, CardBinResponse>({
    url: `https://api.pagar.me/bin/v1/${onlyNumbers?.slice(0, 6)}`,
  });

  useEffect(() => {
    if (onlyNumbers?.length >= 6) fetchBin();
  }, [props.initialValue]);

  useEffect(() => {
    if (onlyNumbers?.length === 6) fetchBin();
  }, [onlyNumbers]);

  return (
    <Form.Field
      name="cardNumber"
      initialValue={cardNumber}
      onValueChange={handleCardNumber}
      validators={[Validators.Required(), Validators.CardNumberRegex()]}
    >
      <InputLabel>Número do cartão</InputLabel>
      <Hbox vAlign="center">
        <Hbox.Item>
          <TextFieldMasked kind="credit-card" type={'tel'} autoComplete="cc-number" />
        </Hbox.Item>
        {binData?.brandImage && !binError && (
          <>
            <Hbox.Separator />
            <Hbox.Item wrap>
              <CardFlagWrapper>
                <LazyLoadImage src={binData.brandImage} alt={binData.brandName} width={40} />
              </CardFlagWrapper>
            </Hbox.Item>
          </>
        )}
      </Hbox>
    </Form.Field>
  );
};
