import { formatAsPrice } from '@global/utils/number/format';
import { Body, Separator } from '@web/atomic';
import React from 'react';
import OrderSuccess from '../../order-success/order-success.component';
import { PixCodeCopier } from './pix-code-copier.component';

interface ICheckoutPixInfoProps {
  qr_code: string;
  qr_code_url: string;
  amount: number;
}

export const CheckoutPixInfo: React.FunctionComponent<ICheckoutPixInfoProps> = (props) => {
  return (
    <>
      <OrderSuccess headline={'Sucesso 🎉! Agora só falta pagar usando o Pix Copia e Cola!'} hideDownload={true}>
        <>
          <Body>
            Abaixo você encontra o PIX no valor de {formatAsPrice(props.amount / 100, true)}. Assim que o pagamento for identificado o
            programa será liberado para você.
          </Body>
          <Body>Copie o código abaixo e utilize o Pix Copia e Cola no aplicativo que você vai fazer o pagamento:</Body>
          <Separator />
          <PixCodeCopier code={props.qr_code} />
        </>
      </OrderSuccess>
    </>
  );
};
