import { GatsbyButton } from '@components/atm.button/button.component';
import { BackButton } from '@lp-root/src/components/atm.back-button';
import { Col, FaIcon, Grid, H2, InputLabel, Row, Separator } from '@web/atomic';
import { EnhancedSelect } from '@web/atomic/legacy/atm.enhanced-select/enhanced-select.component';
import { TextFieldMasked } from '@web/atomic/legacy/atm.text-field/text-field-masked.component';
import { TextField } from '@web/atomic/legacy/atm.text-field/text-field.component';
import { Form, FormContext, FormContextState, FormData, Validators } from '@web/atomic/legacy/obj.form';
import { sendDataLayer } from '@web/utils/analytics';
import React from 'react';
import { CardDataFormData } from '../utils/checkout-page.model';
import { CardNumberField } from './card-number-field.component';

interface Installment {
  value: number;
  label: string;
}

interface ICardDataFormProps {
  initialData: CardDataFormData;
  installments: {
    options: Installment[];
    value: Installment;
    onInstallmentSelection: (data: Installment) => void;
  };
  onBackButton: (data: CardDataFormData) => void;
  onCardSubmit: (data: CardDataFormData) => void;
}

export const CardDataForm: React.FunctionComponent<ICardDataFormProps> = (props) => {
  const handleSubmit = async (formData: FormData<CardDataFormData>) => {
    if (Object.keys(formData.error).length !== 0) return;
    sendDataLayer({
      event: 'custom_click',
      id: `ga-checkout-submit`,
    });
    const data = formData.data;
    props.onCardSubmit(data);
  };

  return (
    <Grid>
      <Form onSubmit={handleSubmit}>
        <FormContext.Consumer>
          {(formConsumer: FormContextState) => {
            const handleBackClick = () => {
              const formData: FormData<CardDataFormData> = formConsumer.getFormData() as any;
              props.onBackButton(formData.data);
            };
            return (
              <Row mt>
                <Col xs={12} sm={10} md={8} lg={6}>
                  <BackButton onClick={handleBackClick} forceShow={true} />
                </Col>
              </Row>
            );
          }}
        </FormContext.Consumer>

        <Row mb>
          <Col xs={12} sm={10} md={8} lg={6}>
            <H2>Dados do cartão de crédito</H2>
          </Col>
        </Row>
        <Row mb>
          <Col xs={12} sm={10} md={8} lg={6}>
            <CardNumberField initialValue={props.initialData?.cardNumber} />
            <Separator />
          </Col>
        </Row>
        <Row mb>
          <Col xs={12} sm={10} md={8} lg={6}>
            <Form.Field name="ccname" initialValue={props.initialData?.ccname} validators={[Validators.Required()]}>
              <InputLabel>Nome como está no cartão</InputLabel>
              <TextField type="text" autoComplete="cc-name" letterCase="upper" />
            </Form.Field>
            <Separator />
          </Col>
        </Row>
        <Row mb>
          <Col xs={12} sm={10} md={8} lg={6}>
            <Form.Field
              name="expirationdate"
              initialValue={props.initialData?.expirationdate}
              validators={[
                Validators.Required(),
                Validators.ExpirationDateRegex('Por favor, digite uma data de expiração válida (no futuro).'),
              ]}
            >
              <InputLabel>Validade (mm/aa ou mm/aaaa)</InputLabel>
              <TextFieldMasked kind="datetime" type="tel" options={{ format: 'MM/YYYY' }} autoComplete="cc-exp" />
            </Form.Field>
            <Separator />
          </Col>
        </Row>
        <Row mb>
          <Col xs={12} sm={10} md={8} lg={6}>
            <Form.Field
              name="cvv2"
              initialValue={props.initialData?.cvv2}
              validators={[Validators.Required(), Validators.MinLength(3, 'O código deve ter entre 3 e 4 dígitos.')]}
            >
              <InputLabel
                role="tooltip"
                data-microtip-position="right"
                data-microtip-size="medium"
                aria-label={`Na maioria dos cartões, o CVV fica na parte de trás e é um código com 3 números`}
              >
                CVV <FaIcon.Question />
              </InputLabel>
              <TextFieldMasked kind="only-numbers" type="tel" maxLength={4} autoComplete="cc-csc" />
            </Form.Field>
            <Separator />
          </Col>
        </Row>
        <Row mb>
          <Col xs={12} sm={10} md={8} lg={6}>
            <Form.Field
              name="installments"
              initialValue={props.installments?.value}
              validators={[Validators.Required()]}
              onValueChange={props.installments.onInstallmentSelection}
            >
              <InputLabel>Número de parcelas (até 12 vezes)</InputLabel>
              <EnhancedSelect options={props.installments?.options} />
            </Form.Field>
            <Separator />
          </Col>
        </Row>

        <Row mb>
          <Col xs={12} sm={10} md={8} lg={6}>
            <GatsbyButton type="submit" kind="primary" expanded>
              Continuar
            </GatsbyButton>
          </Col>
        </Row>
      </Form>
    </Grid>
  );
};
