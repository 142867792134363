import { GatsbyButton } from '@components/atm.button/button.component';
import { brazilianStates } from '@global/utils/object/brazilian-states';
import { Body, Col, H2, InputLabel, Row, Separator } from '@web/atomic';
import { onlyNumber } from '@global/utils/string/only-number';
import { SelectField } from '@web/atomic/legacy/atm.select';
import { TextFieldMasked } from '@web/atomic/legacy/atm.text-field/text-field-masked.component';
import { TextField } from '@web/atomic/legacy/atm.text-field/text-field.component';
import { Form, Validators } from '@web/atomic/legacy/obj.form';
import { useField } from '@web/atomic/obj.custom-hooks/field.hook';
import { useGetUsingNoAuth } from '@web/data/use-get-with-no-auth';
import React, { useEffect } from 'react';
import { AddressFormData } from '../utils/checkout-page.model';

interface GetAddressResponse {
  neighborhood: string;
  street: string;
  city: string;
  state: string;
  zipcode: string;
}

interface IAddressFormProps {
  initialData: AddressFormData;
}

export const AddressForm: React.FunctionComponent<IAddressFormProps> = (props) => {
  const [cep, handleCep] = useField<string>();

  const { data: addressData, loading: addressLoading, refetch: fetchAddress } = useGetUsingNoAuth<unknown, GetAddressResponse>({
    url: `https://api.pagar.me/1/zipcodes/${onlyNumber(cep)}`,
    dontFetchOnLoad: true,
  });

  useEffect(() => {
    if (cep && cep.length === 9) {
      fetchAddress();
    }
  }, [cep]);

  return (
    <>
      <Row mb>
        <Col xs={12} sm={10} md={8} lg={6}>
          <H2>Endereço de cobrança</H2>
          <Body>Preencha primeiro o CEP para ajudarmos a preencher seu endereço.</Body>
        </Col>
      </Row>

      <Row mb>
        <Col xs={12} sm={10} md={8} lg={6}>
          <Form.Field
            name="zipCode"
            onValueChange={handleCep}
            initialValue={props.initialData?.zipCode}
            validators={[Validators.Required(), Validators.ZipCodeRegex('O cep digitado é inválido')]}
          >
            <InputLabel>CEP</InputLabel>
            <TextFieldMasked kind="zip-code" type="tel" autoComplete="postal-code" />
          </Form.Field>
          <Separator />
          <GatsbyButton kind="link" external to={'http://www.buscacep.correios.com.br/sistemas/buscacep/'}>
            Não sei meu cep
          </GatsbyButton>
          <Separator />
        </Col>
      </Row>

      <Row mb>
        <Col xs={12} sm={10} md={8} lg={6}>
          <Form.Field
            name="street"
            key={addressData?.street}
            initialValue={addressData?.street || props.initialData?.street}
            onValueChange={handleCep}
            validators={[Validators.Required()]}
          >
            <InputLabel>Logradouro (rua, avenida ou similar)</InputLabel>
            <TextField type="text" autoComplete="street-address" disabled={addressLoading} />
          </Form.Field>
          <Separator />
        </Col>
      </Row>

      <Row mb>
        <Col xs={12} sm={10} md={8} lg={6}>
          <Form.Field name="number" initialValue={props.initialData?.number} onValueChange={handleCep} validators={[Validators.Required()]}>
            <InputLabel>Número</InputLabel>
            <TextFieldMasked kind="only-numbers" type="tel" maxLength={6} autoComplete="off" disabled={addressLoading} />
          </Form.Field>
          <Separator />
        </Col>
      </Row>

      <Row mb>
        <Col xs={12} sm={10} md={8} lg={6}>
          <Form.Field name="complement" initialValue={props.initialData?.complement}>
            <InputLabel>Complemento</InputLabel>
            <TextField type="text" autoComplete="off" />
          </Form.Field>
          <Separator />
        </Col>
      </Row>

      <Row mb>
        <Col xs={12} sm={10} md={8} lg={6}>
          <Form.Field
            name="neighborhood"
            key={addressData?.neighborhood}
            initialValue={addressData?.neighborhood ?? props.initialData?.neighborhood}
            validators={[Validators.Required()]}
          >
            <InputLabel>Bairro</InputLabel>
            <TextField type="text" autoComplete="off" disabled={addressLoading} />
          </Form.Field>
          <Separator />
        </Col>
      </Row>

      <Row mb>
        <Col xs={12} sm={10} md={8} lg={6}>
          <Form.Field
            name="city"
            key={addressData?.city}
            initialValue={addressData?.city ?? props.initialData?.city}
            validators={[Validators.Required()]}
          >
            <InputLabel>Cidade</InputLabel>
            <TextField type="text" autoComplete="off" disabled={addressLoading} />
          </Form.Field>
          <Separator />
        </Col>
      </Row>

      <Row mb>
        <Col xs={12} sm={10} md={8} lg={6}>
          <Form.Field
            name={'state'}
            key={addressData?.state}
            initialValue={addressData?.state ?? props.initialData?.state}
            validators={[Validators.Required()]}
          >
            <InputLabel>Estado</InputLabel>
            <SelectField disabled={addressLoading}>
              <option aria-selected={false} value={null} />
              {brazilianStates.map((item) => (
                <option value={item.value} aria-selected={false} key={item.value}>
                  {item.name}
                </option>
              ))}
            </SelectField>
          </Form.Field>
          <Separator />
        </Col>
      </Row>
    </>
  );
};
