import { sendDataLayer } from '@web/utils/analytics';
import { useQueryParams } from '@web/atomic/obj.custom-hooks/query-params';
import { NativeHelper } from '@web/data/native.helper';
import { navigate } from 'gatsby';
import { useEffect, useState } from 'react';
import {
  BillingFormData,
  CheckoutPageDecodedQueryParams,
  CheckoutPageQueryParams,
  CheckoutPageStep,
  CheckoutPaymentMethods,
  CheckoutPaymentMethodStrategy,
} from '../utils/checkout-page.model';
import { useBoletoPaymentMethodStrategy } from './boleto-payment-method-strategy.hook';
import { useCreditCardPaymentMethodStrategy } from './credit-card-payment-method-strategy.hook';
import { usePixPaymentMethodStrategy } from './pix-payment-method-strategy.hook';
import { NativeConfigWebviewExtraDataUserInfo } from '@global/utils/native/native-config';

export const useCheckoutContainer = () => {
  const { userId: userIdFromQueryParams, amount: defaultAmount, oldAmount: defaultOldAmount } = useCheckoutPageSetupInfo();
  const userId = NativeHelper.userId || userIdFromQueryParams;

  const [amount, setAmount] = useState<number>();
  const [oldAmount, setOldAmount] = useState<number>();
  useEffect(() => {
    setAmount(defaultAmount);
    setOldAmount(defaultOldAmount);
  }, [defaultOldAmount, defaultAmount]);

  const { name, email, phone } = (NativeHelper.extraData as NativeConfigWebviewExtraDataUserInfo) ?? {};

  const [step, setStep] = useState(CheckoutPageStep.PaymentSelection);
  const changeStep = (nextStep: CheckoutPageStep) => {
    window.scrollTo({ left: 0, top: 0 });
    setStep(nextStep);
  };

  useEffect(() => {
    sendDataLayer({
      event: 'custom_view',
      id: `checkout-${step}`,
    });
  }, [step]);

  // Payment selection
  const [paymentMethod, setPaymentMethod] = useState<CheckoutPaymentMethods>();
  const handlePaymentMethodSelection = (paymentMethod: CheckoutPaymentMethods) => {
    sendDataLayer({
      event: 'custom_click',
      id: `ga-checkout-payment-method-selected-${paymentMethod}`,
    });
    setPaymentMethod(paymentMethod);
    if (paymentMethod === CheckoutPaymentMethods.CreditCard) {
      changeStep(CheckoutPageStep.CardData);
    } else {
      changeStep(CheckoutPageStep.BillingData);
    }
  };

  const handleAmountChange = (total: number, oldTotal: number) => {
    setAmount(total);
    setOldAmount(oldTotal);
  };
  const creditCardPaymentMethodStrategy = useCreditCardPaymentMethodStrategy(
    userId,
    {
      default: defaultAmount,
      defaultOld: defaultOldAmount,
    },
    handleAmountChange,
    changeStep
  );
  const alternativeUserName = creditCardPaymentMethodStrategy.creditCard?.ccname;
  const boletoPaymentMethodStrategy = useBoletoPaymentMethodStrategy(userId, alternativeUserName, changeStep);
  const pixPaymentMethodStrategy = usePixPaymentMethodStrategy(userId, alternativeUserName, changeStep);
  let currentStrategy: CheckoutPaymentMethodStrategy;
  switch (paymentMethod) {
    case CheckoutPaymentMethods.Boleto:
      currentStrategy = boletoPaymentMethodStrategy;
      break;
    case CheckoutPaymentMethods.CreditCard:
      currentStrategy = creditCardPaymentMethodStrategy;
      break;
    case CheckoutPaymentMethods.Pix:
      currentStrategy = pixPaymentMethodStrategy;
      break;
  }

  const handlePaymentSubmission = async (data: BillingFormData) => {
    await currentStrategy.performPaymentSubmission(data);
    sendDataLayer({
      event: 'custom_view',
      id: `checkout-success`,
      value: amount,
    });
  };

  const [billingData, setBillingData] = useState<BillingFormData>();
  const handleBackClickOnBillingData = (data: BillingFormData) => {
    setBillingData(data);
    changeStep(currentStrategy.previousStepFromBillingDataStep);
  };

  return {
    userId,
    amount,
    oldAmount,

    name,
    alternativeUserName,
    email,
    phone,

    step,
    paymentMethod,

    billingData,

    creditCardPaymentMethodStrategy,
    boletoPaymentMethodStrategy,
    pixPaymentMethodStrategy,

    paymentSubmissionLoading: currentStrategy?.submitLoading,

    handlePaymentSubmission,
    handlePaymentMethodSelection,
    handleBackClickOnBillingData,
  };
};

function useCheckoutPageSetupInfo(): {
  userId: string;
  amount: number;
  oldAmount: number;
} {
  let input: CheckoutPageDecodedQueryParams = null;
  const queryParams = useQueryParams<CheckoutPageQueryParams>();
  try {
    const decodedQueryParams = queryParams.d && atob(queryParams.d);
    input = decodedQueryParams && JSON.parse(decodedQueryParams);
    if (decodedQueryParams && (!input?.userId || !input?.price)) {
      navigate('/404');
    }
  } catch (error) {
    console.error(error);
    navigate('/404');
  }
  const userId = input?.userId;
  const oldAmount = input?.priceOld && Math.round(input.priceOld * 100);
  const amount = input?.price && Math.round(input.price * 100);
  return { userId, amount, oldAmount };
}
