import { graphql } from 'gatsby';
import React from 'react';
import WebviewLayout from '../components/org.layout/webview-layout.component';
import { BillingData } from '../modules/checkout/components/billing-data.component';
import { CardDataForm } from '../modules/checkout/components/card-data-form.component';
import { CheckoutBoletoInfo } from '../modules/checkout/components/checkout-boleto-info.component';
import { CheckoutFooter } from '../modules/checkout/components/checkout-footer.component';
import { CheckoutPaymentSelection } from '../modules/checkout/components/checkout-payment-selection.component';
import { CheckoutPixInfo } from '../modules/checkout/components/checkout-pix-info.component';
import { useCheckoutContainer } from '../modules/checkout/hooks/checkout-container.hook';
import { CheckoutPageStep } from '../modules/checkout/utils/checkout-page.model';
import { PageProps } from '../utils/local-types';

/**
 * To test this page on development environment, you may need to pass the "d"
 * query-param. For instance: open
 * `http://localhost:8000/checkout/?d=eyJ1c2VySWQiOiIxMjMiLCJwcmljZU9sZCI6IjE1MCIsInByaWNlIjoiMTAwIn0=`
 *
 * What's the "d" query-param?
 * "d" is a base64 enconded string of an object containing the checkout info.
 * Example (you can execute the following on the Chrome Dev Tools):
 * `d = btoa(JSON.stringify({userId: 'blaYcyiq11XTWkA9JMVlJutBWFh1', priceOld: '150', price: '100'}))`
 */
const CheckoutPage: React.FunctionComponent<PageProps> = () => {
  const {
    userId,
    amount,
    oldAmount,

    name,
    alternativeUserName,
    email,
    phone,

    step,
    paymentMethod,

    billingData,

    creditCardPaymentMethodStrategy,
    boletoPaymentMethodStrategy,
    pixPaymentMethodStrategy,
    paymentSubmissionLoading,
    handlePaymentSubmission,
    handlePaymentMethodSelection,
    handleBackClickOnBillingData,
  } = useCheckoutContainer();

  if (!userId || !amount) {
    return null;
  }

  return (
    <WebviewLayout hideThemeChanger>
      {step === CheckoutPageStep.PaymentSelection && (
        <CheckoutPaymentSelection
          paymentMethods={[creditCardPaymentMethodStrategy, boletoPaymentMethodStrategy, pixPaymentMethodStrategy]}
          onSelect={handlePaymentMethodSelection}
        />
      )}

      {step === CheckoutPageStep.CardData && (
        <CardDataForm
          initialData={creditCardPaymentMethodStrategy.creditCard}
          installments={{
            ...creditCardPaymentMethodStrategy.installments,
            onInstallmentSelection: creditCardPaymentMethodStrategy.installments.handleInstallmentSelection,
          }}
          onCardSubmit={creditCardPaymentMethodStrategy.handleCardSubmit}
          onBackButton={creditCardPaymentMethodStrategy.handleBackFromCardForm}
        />
      )}

      {step === CheckoutPageStep.BillingData && (
        <BillingData
          hideName={!!alternativeUserName}
          name={alternativeUserName ?? name}
          email={email}
          phone={phone}
          initialData={billingData}
          paymentMethod={paymentMethod}
          onSubmit={handlePaymentSubmission}
          onBackButton={handleBackClickOnBillingData}
          submitLoading={paymentSubmissionLoading}
        />
      )}

      {step === CheckoutPageStep.Boleto && <CheckoutBoletoInfo {...boletoPaymentMethodStrategy.boleto} />}

      {step === CheckoutPageStep.Pix && <CheckoutPixInfo {...pixPaymentMethodStrategy.pix} />}

      {[CheckoutPageStep.PaymentSelection, CheckoutPageStep.CardData, CheckoutPageStep.BillingData].includes(step) && (
        <CheckoutFooter amount={amount} oldAmount={oldAmount} />
      )}
    </WebviewLayout>
  );
};

export default CheckoutPage;

export const query = graphql`
  query CheckoutPageQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
