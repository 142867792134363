import { ErrorName } from '@global/utils/api/error-name';
import flashDispatcherService from '@web/atomic/legacy/obj.flash-wrapper/flash-dispatcher.service';

export const handleCheckoutErrorHOF = (onNotAuthorizedError?: () => void) => (err) => {
  const errorName = err?.data?.name;
  let errorMsg = 'Ocorreu um erro. Por favor, tente novamente em 1 minuto.';
  if (
    [
      ErrorName.InvalidSetupPagarMeServiceError,
      ErrorName.NotAuthorizedPagarMeServiceError,
      ErrorName.ValidationErrorPagarMeServiceError,
    ].includes(errorName)
  ) {
    errorMsg = err.data.message;
  } else if (errorName === ErrorName.BadRequestError) {
    errorMsg = 'Ocorreu um erro. Verifique os dados inseridos e tente novamente.';
  } else if (errorName === ErrorName.InvalidPhoneError) {
    errorMsg = 'Telefone inválido. Verifique o telefone inserido e tente novamente.';
  } else if (errorName === ErrorName.NotFoundError) {
    errorMsg = 'Parece que há um erro com o seu usuário. Por favor, entre em contato conosco para resolvermos sua situação.';
  }
  flashDispatcherService.dispatchMessage(errorMsg, 'alert');
  if (errorName === ErrorName.NotAuthorizedPagarMeServiceError && onNotAuthorizedError) {
    onNotAuthorizedError();
  }
};
