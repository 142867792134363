import {
  CheckoutCreditCardRequest,
  CheckoutCreditCardResponse,
  PostCheckoutCreditCardUrl,
} from '@global/utils/api/checkout.controller.model';
import { PagarmeInstallmentPriceCalculator } from '@global/utils/domain/pagarme';
import { formatAsPrice } from '@global/utils/number/format';
import { isNullOrUndefined } from '@global/utils/object/null-or-undefined';
import { onlyNumber } from '@global/utils/string/only-number';
import { FaIcon } from '@web/atomic';
import { usePostUsingNoAuth } from '@web/data/use-post-with-no-auth';
import { navigate } from 'gatsby';
import { useEffect, useState } from 'react';
import {
  BillingFormData,
  CardDataFormData,
  CheckoutPageStep,
  CheckoutPaymentMethods,
  CheckoutPaymentMethodStrategy,
} from '../utils/checkout-page.model';
import { handleCheckoutErrorHOF } from '../utils/handle-checkout-error-hof';
import { useCardTokenization } from './card-tokenization.hook';

export interface CreditCardInstallment {
  value: number;
  label: string;
}

interface UseCreditCardPaymentMethodStrategy extends CheckoutPaymentMethodStrategy {
  creditCard: CardDataFormData;
  installments: {
    options: CreditCardInstallment[];
    value: CreditCardInstallment;
    handleInstallmentSelection: (data: CreditCardInstallment) => void;
  };
  handleCardSubmit: (data: CardDataFormData) => void;
  handleBackFromCardForm: (data: CardDataFormData) => void;
}
export const useCreditCardPaymentMethodStrategy = (
  userId: string,
  amount: {
    /** the default order price */
    default: number;
    /** the default order old/previous price */
    defaultOld: number;
  },
  onAmountChange: (value: number, oldValue: number) => void,
  changeStep: (nextStep: CheckoutPageStep) => void
): UseCreditCardPaymentMethodStrategy => {
  const { installmentOptions, installment, handleInstallmentSelection, resetInstallmentSelection } = useInstallments(
    amount,
    onAmountChange
  );

  const [creditCard, setCreditCard] = useState<CardDataFormData>();

  const handleCardSubmit = (data: CardDataFormData) => {
    setCreditCard(data);
    changeStep(CheckoutPageStep.BillingData);
  };
  const handleBackFromCardForm = (data: CardDataFormData) => {
    resetInstallmentSelection();
    setCreditCard(data);
    changeStep(CheckoutPageStep.PaymentSelection);
  };

  const goToCardData = () => changeStep(CheckoutPageStep.CardData);
  const [tokenizeCard, tokenizeCardState] = useCardTokenization(goToCardData);
  const [checkoutCreditCard, checkoutCreditCardState] = usePostUsingNoAuth<CheckoutCreditCardRequest, CheckoutCreditCardResponse>({
    url: PostCheckoutCreditCardUrl,
    onError: handleCheckoutErrorHOF(goToCardData),
  });

  const performPaymentSubmission = async (data: BillingFormData) => {
    const getLine1Address = (d: BillingFormData) => `${d.number}, ${d.street}, ${d.neighborhood}`;
    const getLine2Address = (d: BillingFormData) => `${isNullOrUndefined(d.complement) ? '' : d.complement}`;

    const [expMonth, expYear] = creditCard.expirationdate.split('/');
    const response = await tokenizeCard({
      type: 'card',
      card: {
        number: onlyNumber(creditCard.cardNumber),
        holder_name: creditCard.ccname,
        exp_month: +expMonth,
        exp_year: +expYear,
        cvv: creditCard.cvv2,
      },
    });
    await checkoutCreditCard({
      userId,
      personal: {
        name: data.name ?? creditCard.ccname,
        email: data.email,
        document: data.document,
        phone: data.phone,
      },
      card_token: response.id,
      installments: installment.value,
      billing_address: {
        line_1: getLine1Address(data),
        line_2: getLine2Address(data),
        zip_code: data.zipCode,
        city: data.city,
        state: data.state,
      },
    });
    navigate('/comecar/compra-realizada-app');
  };

  const submitLoading = tokenizeCardState.loading || checkoutCreditCardState.loading;

  return {
    creditCard,
    installments: {
      options: installmentOptions,
      value: installment,
      handleInstallmentSelection,
    },
    handleCardSubmit,
    handleBackFromCardForm,

    // CheckoutPaymentMethodStrategy
    performPaymentSubmission,
    submitLoading,
    nextStepFromPaymentSelection: CheckoutPageStep.CardData,
    previousStepFromBillingDataStep: CheckoutPageStep.CardData,

    // CheckoutPaymentMethodData
    raw: CheckoutPaymentMethods.CreditCard,
    icon: FaIcon.CreditCard,
    name: 'Cartão de crédito',
  };
};

const useInstallments = (
  amount: {
    default: number;
    defaultOld: number;
  },
  onAmountChange: (value: number, oldValue: number) => void
) => {
  const installmentOptions: CreditCardInstallment[] = Array(12)
    .fill(1)
    .map((_v, index) => {
      const value = index + 1;
      const priceCalculator = new PagarmeInstallmentPriceCalculator(value);
      const installment = priceCalculator.computeInstallmentPrice(amount.default);
      const total = priceCalculator.computeNewPrice(amount.default);
      return {
        value,
        label: `${value}x ${formatAsPrice(installment / 100)} (= ${formatAsPrice(total / 100)})`,
      };
    });
  const [installment, setInstallment] = useState<CreditCardInstallment>();
  useEffect(() => {
    setInstallment(installmentOptions[0]);
  }, [installmentOptions?.[0]?.label]);
  const handleInstallmentSelection = (data: CreditCardInstallment) => {
    const total = new PagarmeInstallmentPriceCalculator(data.value).computeNewPrice(amount.default);
    const oldTotal = new PagarmeInstallmentPriceCalculator(data.value).computeNewPrice(amount.defaultOld);
    onAmountChange(total, oldTotal);
    setInstallment(data);
  };
  const resetInstallmentSelection = () => {
    handleInstallmentSelection(installmentOptions[0]);
  };

  return {
    resetInstallmentSelection,
    installmentOptions,
    installment,
    handleInstallmentSelection,
  };
};
