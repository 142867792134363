import { IapPlatform, IapType } from '../third-party/iap';
import { ErrorName } from './error-name';

export const PostCheckoutUrl = '/checkout';

export interface CheckoutRequest {
  user_id: string;
  receipt: string;
  product: IapType;
  platform: IapPlatform;
  product_id: string;
  package_name: string;
}

export interface CheckoutResponse {
  expiration?: Date;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export const PostVerifyIosReceipt = '/verify-ios-receipt';
export interface VerifyIosReceiptRequest {
  user_id: string;
  receipt: string;
  package_name: string;
  product_id: string;
}
export interface VerifyIosReceiptResponse {
  /**
   * https://developer.apple.com/documentation/appstorereceipts/status
   * The value for status is 0 if the receipt is valid, or a status code if
   * there’s an error. The status code reflects the status of the app receipt as
   * a whole. For example, if you send a valid app receipt that contains an
   * expired subscription, the response is 0 because the receipt is valid.
   */
  status: number;

  /**
   * ios appAccountToken and android obfuscatedExternalAccountId
   */
  user_uuid: string;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export const GetSkuUrl = '/patient-subscription-sku';
export const GetSkuUrlV2 = '/patient-subscription-sku-v2';

export interface GetSkuRequest {
  user_id: string;
  platform: 'android' | 'ios' | 'web';
  app_version?: string;
}

export enum SkuKind {
  IapSubscription = 'iap_subscription',
  IapProduct = 'iap_product',
  WebProduct = 'web_product',
}

export interface GetSkuResponse {
  price: number;
  priceOld?: number;
  sku: string;
  skuUrl?: string;
  skuKind?: SkuKind;
}

export interface GetSkuResponseV2 {
  price: number;
  priceOld?: number;
  sku: string;
  skuKind?: SkuKind;
  numberOfTelehealthAppointments: number;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export class InvalidPackageNameError extends Error {
  constructor(name: string) {
    const message = `invalid package name: ${name}`;
    super(message);
    this.name = ErrorName.InvalidPackageName;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class InvalidProductId extends Error {
  constructor(productId: string) {
    const message = `invalid product id ${productId}`;
    super(message);
    this.name = ErrorName.InvalidProductId;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
export class EmptyTransactionIdError extends Error {
  constructor() {
    const message = `Transaction id should be sent`;
    super(message);
    this.name = ErrorName.EmptyTransactionIdError;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export interface BaseCheckoutRequest {
  userId: string;
  personal: {
    name: string;
    email: string;
    document: string;
    phone: string;
  };
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export const PostCheckoutCreditCardUrl = '/checkout-credit-card';
export interface CheckoutCreditCardRequest extends BaseCheckoutRequest {
  card_token: string;
  installments: number;
  billing_address: {
    // Dados principais do endereço. Neste campo deve ser informado Número, Rua, Bairro, nesta ordem e separados por vírgula.
    line_1: string;
    // Dados complementares do endereço. Neste campo pode ser informado complemento, referências.
    line_2: string;
    zip_code: string;
    city: string;
    state: string;
  };
}
export interface CheckoutCreditCardResponse {
  amount: number;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export const PostCheckoutBoletoUrl = '/checkout-boleto';

export type CheckoutBoletoRequest = BaseCheckoutRequest;
export interface CheckoutBoletoResponse {
  amount: number;
  due_at: string;
  url: string;
  pdf_url: string;
  line: string;
  barcode_img: string;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

export const PostCheckoutPixUrl = '/checkout-pix';

export type CheckoutPixRequest = BaseCheckoutRequest;
export interface CheckoutPixResponse {
  amount: number;
  qr_code: string;
  qr_code_url: string;
}
