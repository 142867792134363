import { GatsbyButton } from '@components/atm.button/button.component';
import { sendDataLayer } from '@web/utils/analytics';
import { Hbox } from '@web/atomic';
import { copyHof } from '@web/utils/copy-or-share-info.hook';
import React from 'react';
import { PixCodeText } from './pix-code-copier.component.styled';

interface IPixCodeCopierProps {
  code: string;
}

export const PixCodeCopier: React.FunctionComponent<IPixCodeCopierProps> = (props) => {
  const successMessage = 'Código copiado! Utilize o "Pix Copia e Cola" no aplicativo que você vai fazer o pagamento.';
  const errorMessage = 'Não foi possível copiar o código. 😢';
  const copy = copyHof({ successMessage, errorMessage });

  const copyPixCode = () => {
    copy(props.code);
    sendDataLayer({
      event: 'custom_click',
      id: `ga-PixCodeCopier-copy`,
    });
  };

  const handleCopyClick: React.ClipboardEventHandler<HTMLParagraphElement> = (ev) => {
    const copiedText = window
      .getSelection()
      .toString()
      .replace(/[\n\r]+/g, '');
    /** if copied text is not the same as the pix code, then overwrite the clipboard */
    if (copiedText !== props.code) {
      ev.clipboardData.setData('text/plain', props.code);
      ev.preventDefault();
    }
  };

  return (
    <>
      <PixCodeText onCopy={handleCopyClick}>{props.code}</PixCodeText>
      <Hbox hAlign="center">
        <GatsbyButton onClick={copyPixCode}> Copiar código </GatsbyButton>
      </Hbox>
    </>
  );
};
